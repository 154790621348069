import {RootMessageListener} from '../../app/root-message-listener';
import {AngularEnvironment} from '@sporesoftware/angular-processing';
import {Application, Environments, ListenerKey} from '@sporesoftware/processing';
import {ngBootstrap} from '../../app/app.config';

const config = {
    Logging: {
        logLevels: {
            Default: 'Debug'
        },
        sinks: {
            Console: {
                logLevel: 'Debug'
            }
        }
    },
    seertech: {
        url: 'https://seertech-api-node.sandler-dev.sporesoftware.com'
    }
};

export const angularEnvironment: AngularEnvironment = new AngularEnvironment([new ListenerKey(RootMessageListener)], Environments.development, config);
angularEnvironment.setApplicationFactory((listener) => ngBootstrap(listener));

Application.main(angularEnvironment);
