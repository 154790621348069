import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {JobResult, UserAttributes} from '@sandler/lms';
import {throwError} from 'rxjs';

export class SeertechService {
    constructor(public url: string) {}

    private _http = inject(HttpClient);

    updateUser(userAttributes: UserAttributes) {
        return this._http.post<JobResult>(new URL('/api/build-url-for-user', this.url).toString(), userAttributes);
    }
}
