import {
  provideRouter,
  withEnabledBlockingInitialNavigation,
} from '@angular/router';
import { appRoutes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import {AngularEnvironment, AngularPlatformMessageListener} from '@sporesoftware/angular-processing';
import {bootstrapApplication} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {Environment} from '@sporesoftware/processing';

export function ngBootstrap(listener: AngularPlatformMessageListener) {
    return bootstrapApplication(AppComponent, {
        providers: [
            provideRouter(appRoutes, withEnabledBlockingInitialNavigation()),
            provideAnimations(),
            { provide: AngularPlatformMessageListener, useValue: listener },
            { provide: Environment, useValue: listener.environment },
            { provide: AngularEnvironment, useValue: listener.environment }
        ]
    });
}
