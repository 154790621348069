import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatButtonModule} from '@angular/material/button';

@Component({
    selector: 'sandler-assessment-intro',
    standalone: true,
    imports: [CommonModule, MatButtonModule],
    templateUrl: './assessment-intro.component.html',
    styleUrls: ['./assessment-intro.component.scss'],
})
export class AssessmentIntroComponent {
    @Input() firstname?: string;
    @Input() completeByDate?: string;
    @Input() url?: string;
}
