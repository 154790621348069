import {EnvironmentConfigureMessageListener, ListenerKey} from '@sporesoftware/processing';
import {AngularMessageListener, StartAngularMessage} from '@sporesoftware/angular-processing';
import {CancellationToken, getClassName} from '@sporesoftware/language';
import {ApplicationMessageListener} from '@sporesoftware/telemetry-processing';

export class RootMessageListener extends ApplicationMessageListener {
    constructor() {
        super(EnvironmentConfigureMessageListener);
    }

    override [getClassName](): string { return 'RootMessageListener'; }

    override * getDependencies() {
        yield* super.getDependencies();
        yield new ListenerKey(AngularMessageListener);
    }

    protected override async onApplicationRunAsync(cancellationToken: CancellationToken) {
        this.messageProcessor.processMessage(StartAngularMessage, new StartAngularMessage('taw'));
    }
}
