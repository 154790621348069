<ng-container [ngSwitch]="updateResult().status">
    <ng-container *ngSwitchCase="JobStatus.Pending">
        <mat-spinner></mat-spinner>
    </ng-container>
    <ng-container *ngSwitchCase="JobStatus.Success">
        <sandler-assessment-intro [firstname]="updateResult().firstName" [completeByDate]="updateResult().completeByDate" [url]="updateResult().auctusUrl"></sandler-assessment-intro>
    </ng-container>
    <ng-container *ngSwitchDefault>
        There is a problem communicating with the LMS right now.
    </ng-container>
</ng-container>
